import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  allApi,
  messages,
  postApi,
  postApiFile,
} from "../helpers/apiStructure";
import { hardwareSite } from "../const";

const initialState = {
  localStorageCartItem: !!localStorage.getItem("cartItem")
    ? JSON.parse(localStorage.getItem("cartItem"))
    : [],
  getDashBoardData: [],
  getHomePageSettingList: [],
  getRolesData: [],
  getCheckoutDeatilsData: [],
  getUsersData: [],
  usersBlank: [],
  dashboardRecordData: [],
  getSocialMediaSettingsData: [],
  getBannersData: [],
  getSettingsData: [],
  getSettingData: [],
  getShippingsData: [],
  getTransactionsData: [],
  getTransactionsMsg: [],
  getZipcodesData: [],
  getCartlistData: [],
  getProductsData: [],
  getSingleProductData: [],
  getNewProductData: [],
  productsListData: [],
  productsBlank: [],
  getAllStatesList: [],
  fetchAddressData: [],
  getTrackersData:[],
  getPagesList: [],
  getSinglePageData: [],
  getBannerFrontendData: [],
  getCountProductData: []
};



var baseUrl = "http://localhost:3000";

if (window.location.host) {
  baseUrl = window.location.protocol + "//" + window.location.host;
}
//Reward website auto login
export const rewardAutoLogin = createAsyncThunk("rewardAutoLogin", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/verify`, "post", body);
  return result;
}
);
//reset products list
export const resetProductList = createAsyncThunk("resetProductList", async () => {
    return [];
  }
);
//resetUser list
export const resetUsersList = createAsyncThunk( "resetUsersList", async () => {
    return [];
  }
);
export const getDashBoard = createAsyncThunk("getDashBoard", async () => {
  const result = await allApi(`${baseUrl}/api/admin/dashboard`, "get");
  return result;
});
//Home page settings
export const getHomePageSetting = createAsyncThunk(
  "getHomePageSetting",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/home`, "get");
    return result;
  }
);
// social media settings
export const getSocialMediaSettings = createAsyncThunk("getSocialMediaSettings", async (body) => {
    const result = await postApi(`${baseUrl}/api/social`, "post", body);
    return result;
  }
);
export const updateSocialMediaSettings = createAsyncThunk("updateSocialMediaSettings", async (body) => {
    const result = await postApi(`${baseUrl}/api/socialmedia/edit`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
//admin dashboard
export const dashboardRecord = createAsyncThunk(
  "dashboardRecord",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/counter/reports`,
      "post",
      body
    );
    return result;
  }
);
// users manage
export const getUsers = createAsyncThunk("getUsers", async (body) => {
  const result = await postApi(`${baseUrl}/api/users`, "post", body);
  return result;
});
export const getUsersExport = createAsyncThunk("getUsersExport", async (body) => {
  const result = await postApi(`${baseUrl}/api/users/forcsv`, "post", body);
  return result;
});
//forgot password
export const sendVerificationCode = createAsyncThunk(
  "sendVerificationCode",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/email/otp`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const verifyOTP = createAsyncThunk("verifyOTP", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/user/forgetPassword`,
    "post",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const passwordChanges = createAsyncThunk(
  "passwordChanges",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/user/password/change`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
// roles manage
export const addRole = createAsyncThunk("addRole", async (body) => {
  const result = await postApi(`${baseUrl}/api/role/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getRoles = createAsyncThunk("getRoles", async (data) => {
  const result = await allApi(`${baseUrl}/api/roles`, "get");
  return result;
});
export const deleteRole = createAsyncThunk("deleteRole", async (data) => {
  const result = await allApi(`${baseUrl}/api/role/${data}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
//upload products
export const uploadProducts = createAsyncThunk(
  "uploadProducts",
  async (body) => {
    const result = await postApiFile(`${baseUrl}/api/product/upload`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
//upload Users
export const uploadUsers = createAsyncThunk(
  "uploadUsers",
  async (body) => {
    const result = await postApiFile(`${baseUrl}/api/users/upload`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
// Banners manage
export const addBanner = createAsyncThunk("addBanner", async (body) => {
  const result = await postApiFile(`${baseUrl}/api/banner/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getBanners = createAsyncThunk("getBanners", async (data) => {
  const result = await allApi(`${baseUrl}/api/banners`, "get");
  return result;
});
export const deleteBanner = createAsyncThunk("deleteBanner", async (data) => {
  const result = await allApi(`${baseUrl}/api/banner/${data}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
export const updateBanner = createAsyncThunk("updateBanner", async (body) => {
  const result = await postApiFile(
    `${baseUrl}/api/banner/edit/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
// Create Product
export const CreateProduct = createAsyncThunk("CreateProduct", async (body) => {
  const result = await postApiFile(`${baseUrl}/api/product/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateProduct = createAsyncThunk("updateProduct", async (body) => {
  const result = await postApiFile(
    `${baseUrl}/api/product/edit/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const productsList = createAsyncThunk("productsList", async (body) => {
  const result = await postApi(`${baseUrl}/api/backend/products`, "post", body);
  return result;
});
export const productsListExport = createAsyncThunk("productsListExport", async (body) => {
  const result = await postApi(`${baseUrl}/api/products/forcsv`, "post", body);
  return result;
});
export const deleteProduct = createAsyncThunk("deleteProduct", async (data) => {
  const result = await allApi(`${baseUrl}/api/product/${data}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
//serial numbers
export const OrderSerialNumbers = createAsyncThunk("OrderSerialNumbers", async (body) => {
  const result = await postApi(`${baseUrl}/api/order/serialnumber`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
// setting manage
export const addSetting = createAsyncThunk("addSetting", async (body) => {
  const result = await postApi(`${baseUrl}/api/setting/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getSettings = createAsyncThunk("getSettings", async (data) => {
  const result = await allApi(`${baseUrl}/api/settings`, "get");
  return result;
});
export const getSetting = createAsyncThunk("getSetting", async (body) => {
  const result = await postApi(`${baseUrl}/api/setting`, "post", body);
  return result;
});
export const getOpenSetting = createAsyncThunk("getOpenSetting", async (body) => {
  const result = await postApi(`${baseUrl}/api/settings/forcustomer`, "post", body);
  return result;
});
export const deleteSetting = createAsyncThunk("deleteSetting", async (data) => {
  const result = await allApi(`${baseUrl}/api/setting/${data}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
export const updateSetting = createAsyncThunk("updateSetting", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/setting/edit/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
// Shippings manage
export const addShipping = createAsyncThunk("addShipping", async (body) => {
  const result = await postApi(`${baseUrl}/api/shipping/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getShippings = createAsyncThunk("getShippings", async (data) => {
  const result = await allApi(`${baseUrl}/api/shippings`, "get");
  return result;
});
export const deleteShipping = createAsyncThunk(
  "deleteShipping",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/shipping/${data}`, "delete");
    await messages(result?.message, result?.status);
    return result;
  }
);
export const updateShipping = createAsyncThunk(
  "updateShipping",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/shipping/edit/${body?.id}`,
      "put",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
// trackers manage
export const addTracker = createAsyncThunk("addTracker", async (body) => {
  const result = await postApi(`${baseUrl}/api/track/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getTrackers = createAsyncThunk("getTrackers", async (data) => {
  const result = await allApi(`${baseUrl}/api/track/list`, "get");
  return result;
});
export const deleteTracker = createAsyncThunk(
  "deleteTracker",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/track/delete/${data}`, "delete");
    await messages(result?.message, result?.status);
    return result;
  }
);
export const updateTracker = createAsyncThunk("updateTracker", async (body) => {
    const result = await postApi(`${baseUrl}/api/track/edit/${body?.id}`,"put", body);
    await messages(result?.message, result?.status);
    return result;
  }
);

// Transaction manage
export const addTransaction = createAsyncThunk("addTransaction",async (body) => {
    const result = await postApi(`${baseUrl}/api/transaction/add`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const getTransactions = createAsyncThunk("addTransaction", async (body) => {
    const result = await postApi(`${baseUrl}/api/transactions`, "post", body);
    return result;
  }
);
export const getUserWiseTransactions = createAsyncThunk("getUserWiseTransactions", async (body) => {
    const result = await postApi(`${baseUrl}/api/user/transactions`, "post", body);
    return result;
  }
);
export const deleteTransaction = createAsyncThunk("deleteTransaction", async (data) => {
    const result = await allApi(`${baseUrl}/api/transaction/${data}`, "delete");
    await messages(result?.message, result?.status);
    return result;
  }
);
export const updateTransaction = createAsyncThunk("updateTransaction", async (body) => {
    const result = await postApi(`${baseUrl}/api/transaction/edit/${body?.id}`, "put", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
//checkout
export const getCheckoutDeatils = createAsyncThunk("getCheckoutDeatils", async (data) => {
    const result = await allApi(`${baseUrl}/api/checkout`, "get");
    return result;
  }
);
// cart manage
export const addCart = createAsyncThunk("addCart", async (body) => {
  const result = await postApi(`${baseUrl}/api/cart/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getCartlist = createAsyncThunk("getCartlist", async (data) => {
  const result = await allApi(`${baseUrl}/api/cartlist`, "get");
  return result;
});
export const deleteCart = createAsyncThunk("deleteCart", async (data) => {
  const result = await allApi(`${baseUrl}/api/cart/${data}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
export const updateCart = createAsyncThunk("updateCart", async (body) => {
  const result = await postApi(`${baseUrl}/api/cart/edit/${body?.id}`,"put", body);
  return result;
});
export const emptyCartlist = createAsyncThunk("emptyCartlist", async (data) => {
  const result = await allApi(`${baseUrl}/api/cart/empty`, "get");
  return result;
});
// Product manage
export const getProducts = createAsyncThunk("getProducts", async (body) => {
  const result = await postApi(`${baseUrl}/api/products`, "post", body);
  return result;
});
export const getSingleProduct = createAsyncThunk(
  "getSingleProduct",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/getproduct`, "post", body);
    return result;
  }
);
// zipcodes manage
export const addZipcode = createAsyncThunk("addZipcode", async (body) => {
  const result = await postApi(`${baseUrl}/api/zipcode/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getZipcodes = createAsyncThunk("getZipcodes", async (data) => {
  const result = await allApi(`${baseUrl}/api/zipcodes`, "get");
  return result;
});
//all states
export const getAllStates = createAsyncThunk("getAllStates", async (data) => {
  const result = await allApi(`${baseUrl}/api/states`, "get");
  return result;
});
// myprofile manage
export const getMyProfile = createAsyncThunk("getMyProfile", async (data) => {
  const result = await allApi(`${baseUrl}/api/user/profile`, "get");
  return result;
});
export const editMyProfile = createAsyncThunk("editMyProfile", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/profile/edit`,"post", body);
  await messages(result?.message, result?.status);
  return result;
});
// update profile form admin side
export const editProfileByAdmin = createAsyncThunk("editProfileByAdmin", async (body) => {
    const result = await postApi(`${baseUrl}/api/user/edit/${body?.id}`,"put", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const adminLogin = createAsyncThunk("adminLogin", async (body) => {
  const result = await postApi(`${baseUrl}/auth/login`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const userLogOut = createAsyncThunk("userLogOut", async (body) => {
  const result = await postApi(`${baseUrl}/auth/logout`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
// banners
export const getBannerFrontend = createAsyncThunk("getBannerFrontend", async (data) => {
    const result = await allApi(`${baseUrl}/api/frontend/banners`, "get");
    return result;
  }
);
// get count product
export const getCountProduct = createAsyncThunk("getCountProduct", async (body) => {
    const result = await postApi(`${baseUrl}/api/getproduct/limits`, "post", body);
    return result;
  }
);
// pages
export const addNewPage = createAsyncThunk("addNewPage", async (body) => {
  const result = await postApi(`${baseUrl}/api/page/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getSinglePage = createAsyncThunk("getSinglePage", async (body) => {
  const result = await postApi(`${baseUrl}/api/page/${body?.slug}`, "post", body);
  return result;
});
export const getPages = createAsyncThunk("getPages", async (data) => {
  const result = await allApi(`${baseUrl}/api/pages`, "get");
  return result;
});
export const deletePage = createAsyncThunk("deletePage", async (data) => {
  const result = await allApi(`${baseUrl}/api/page/${data}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
export const updatePage = createAsyncThunk("updatePage", async (body) => {
  const result = await postApi(`${baseUrl}/api/page/edit/${body?.id}`, "put", body);
  await messages(result?.message, result?.status);
  return result;
});
// Reward to cashback in pcdeals
export const rewardToCashBack = createAsyncThunk("rewardToCashBack", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/cashbacktransfer`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
// check product exist or not in pcdeals
export const checkProductInPcdeals = createAsyncThunk("checkProductInPcdeals", async (body) => {
  const result = await postApi(`${baseUrl}/api/product/validation`, "post", body);
  if(result?.status === 0){
    await messages(result?.message, result?.status);
  }
  return result;
});

const userReducer = createSlice({
  name: "details",
  initialState,
  reducers: {
    increaseLocalItem: (state) => {
      state.localStorageCartItem += 1;
    },
  },
  extraReducers: {
    [resetProductList.fulfilled]: (state, action) => {
      state.productsListData = [];
    },
    [resetUsersList.fulfilled]: (state, action) => {
      state.getUsersData = [];
      state.getTransactionsData = [];
      state.userTransactions = [];
    },
    [getHomePageSetting.fulfilled]: (state, action) => {
      state.getHomePageSettingList = action.payload;
    },
    [getAllStates.fulfilled]: (state, action) => {
      state.getAllStatesList = action.payload;
    },
    [getSocialMediaSettings.fulfilled]: (state, action) => {
      state.getSocialMediaSettingsData = action.payload?.socialAccounts;
    },
    [getDashBoard.fulfilled]: (state, action) => {
      state.getDashBoardData = action.payload;
    },
    [CreateProduct.fulfilled]: (state, action) => {
      state.getNewProductData = action.payload?.product?.id;
      action.payload?.product?.id.length > 0 &&
        localStorage.setItem("productId", action.payload?.product?.id);
      action.payload?.status === 1 &&
        localStorage.setItem("newProductapiStatus", action.payload?.status);
    },
    [productsList.fulfilled]: (state, action) => {
      state.productsListData = [
        ...state.productsListData,
        ...action.payload?.list,
      ];
      state.productsBlank = action.payload?.list;
    },
    [productsListExport.fulfilled]: (state, action) => {
      state.productsListExportData = action.payload?.list;
    },
    [getRoles.fulfilled]: (state, action) => {
      state.getRolesData = action.payload;
    },
    // [fetchAddressFromPcdeals.fulfilled]: (state, action) => {
    //   state.fetchAddressData = action.payload?.address;
    // },
    [getUsers.fulfilled]: (state, action) => {
      state.getUsersData = [...state.getUsersData, ...action.payload?.list];
      state.usersBlank = action.payload?.list;
    },
    [getUsersExport.fulfilled]: (state, action) => {
      state.getUsersExportList = action.payload?.list;
    },
    [sendVerificationCode.fulfilled]: (state, action) => {
      action.payload?.status === 1 &&
        localStorage.setItem("sendVerificationCode", action.payload?.status);
    },
    [verifyOTP.fulfilled]: (state, action) => {
      action.payload?.status === 1 &&
        localStorage.setItem("verifyOTP", action.payload?.status);
    },
    [passwordChanges.fulfilled]: (state, action) => {
      action.payload?.status === 1 &&
        localStorage.setItem("passwordChanges", action.payload?.status);
    },
    [dashboardRecord.fulfilled]: (state, action) => {
      state.dashboardRecordData = action.payload?.list;
    },
    [getBanners.fulfilled]: (state, action) => {
      state.getBannersData = action.payload;
    },
    [getCheckoutDeatils.fulfilled]: (state, action) => {
      state.getCheckoutDeatilsData = action.payload;
    },
    [getSettings.fulfilled]: (state, action) => {
      state.getSettingsData = action.payload;
    },
    [getSetting.fulfilled]: (state, action) => {
      state.getSettingData = action.payload;
    },
    [getOpenSetting.fulfilled]: (state, action) => {
      state.getSettingData = action.payload;
    },
    [getShippings.fulfilled]: (state, action) => {
      state.getShippingsData = action.payload;
    },
    [getTrackers.fulfilled]: (state, action) => {
      state.getTrackersData = action.payload;
    },
    [getTransactions.fulfilled]: (state, action) => {
      state.getTransactionsData = [...state.getTransactionsData, ...action.payload?.list];
      state.getTransactionsMsg = action.payload?.list;
    },
    [getUserWiseTransactions.fulfilled]: (state, action) => {
      state.userTransactions = [...state.userTransactions, ...action.payload?.list];
      state.userTransactionsMsg = action.payload?.list;
    },
    [getZipcodes.fulfilled]: (state, action) => {
      state.getZipcodesData = action.payload;
    },
    [getCartlist.fulfilled]: (state, action) => {
      state.getCartlistData = action.payload;
    },
    [getProducts.fulfilled]: (state, action) => {
      state.getProductsData = action.payload;
    },
    [getSingleProduct.fulfilled]: (state, action) => {
      state.getSingleProductData = action.payload?.product;
    },
    [getPages.fulfilled]: (state, action) => {
      state.getPagesList = action.payload?.list;
    },
    [getSinglePage.fulfilled]: (state, action) => {
      state.getSinglePageData = action.payload?.page;
    },
    [getBannerFrontend.fulfilled]: (state, action) => {
      state.getBannerFrontendData = action.payload;
    },
    [getCountProduct.fulfilled]: (state, action) => {
      state.getCountProductData = action.payload;
    },
    [getMyProfile.fulfilled]: (state, action) => {
      state.getMyProfileData = action.payload;
    },
    [editMyProfile.pending]: (state, action) => {
      state.editMyProfileMsg = "Please Wait......";
    },
    [editMyProfile.fulfilled]: (state, action) => {
      state.editMyProfileMsg = "Your profile is edit successfully.";
    },
    [editMyProfile.rejected]: (state, action) => {
      state.editMyProfileMsg = action?.error?.message;
    },
    [adminLogin.fulfilled]: (state, action) => {
      if (action.payload.status === 1) {
        localStorage.setItem("x-auth-token", action.payload.token);
        localStorage.setItem("userRole", action.payload?.me?.role?.name);
        localStorage.setItem("slug", action.payload?.me?.role?.slug);
        localStorage.setItem("username", action.payload?.me?.email);
        action.payload?.me?.pcdealUserAddressid &&
          localStorage.setItem(
            "pcdealUserAddressid",
            action.payload?.me?.pcdealUserAddressid
          );
      }
    },
    [rewardAutoLogin.fulfilled]: (state, action) => {
      if (action.payload.status === 1) {
        localStorage.setItem("x-auth-token", action.payload?.user?.token);
        localStorage.setItem("userRole", action.payload?.user?.role?.name);
        localStorage.setItem("slug", action.payload?.user?.role?.slug);
        localStorage.setItem("username", action.payload?.user?.email);
      }
    },
  },
});

export const { increaseLocalItem } = userReducer.actions;
export default userReducer.reducer;
