import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import commonReducer from './reducers/commonReducer';
import { BrowserRouter} from "react-router-dom"
import "react-toastify/dist/ReactToastify.css";
import orderDetailsReducer from './reducers/orderDetailsReducer';
import Loader from './Loader';

const store = configureStore({
  reducer: {
   commonReducer:commonReducer,
   orderDetailReducer: orderDetailsReducer,
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
const App = lazy(() => import('./App'));
root.render(
  
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<Loader/>}>
          <App />
        </Suspense>
      </BrowserRouter>
    </Provider>
);
reportWebVitals();
